import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const AvaluosImg = () => (
  <StaticImage
    src="../../images/avaluos.png"
    alt="avaluos"
  />
);

export default AvaluosImg;
