import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import ListingCard from "../ListingCard";

import { getHighlightedListings } from "../../services/google-functions";
import Spinner from "../Spinner";

class HighlightedListings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      listing1: {},
      listing2: {},
      listing3: {},
    };
  }

  componentDidMount() {
    getHighlightedListings()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            listing1: response.data[0],
            listing2: response.data[1],
            listing3: response.data[2],
          });
        }
      })
      .catch(() => {
        console.log("No fue posible cargar las propiedades destacadas");
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    const {
      isLoading,
      listing1,
      listing2,
      listing3,
    } = this.state;
    return (
      <Fragment>
        {isLoading && (
          <Row className="text-center mt-5">
            <Col>
              <Spinner />
            </Col>
          </Row>
        )}
        {!isLoading && listing1 && listing2 && listing3 && (
          <Row>
            <Col xs="12" className="mt-5 text-center">
              <h4>INMUEBLES DESTACADOS</h4>
              <hr className="title-line mt-4 mb-5" />
            </Col>
            <Col md="6" lg="6" xl="4" className="mb-3">
              <ListingCard listing={listing1} />
            </Col>
            <Col md="6" lg="6" xl="4" className="mb-3">
              <ListingCard listing={listing2} />
            </Col>
            <Col
              md={{ size: "6", offset: "3" }}
              xl={{ size: "4", offset: "0" }}
              className="my-md-4 my-xl-0"
            >
              <ListingCard listing={listing3} />
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default HighlightedListings;
