import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const VentasImg = () => (
  <StaticImage
    src="../../images/ventas.png"
    alt="Orozco Laverde"
  />
);

export default VentasImg;
