import React from "react";
import { Container, Row, Col, Alert } from "reactstrap";
import { Link } from "gatsby";

import LandingLayout from "../components/LandingLayout";

import Search from "../components/Search";
import HighlightedListings from "../components/HighlightedListings";
import ContactForm from "../components/ContactForm";

import LandingSplashImage from "../components/Images/landing-image";
import ArrendamientoImg from "../components/Images/arrendamiento-img";
import VentasImg from "../components/Images/ventas-img";
import HipotecaImg from "../components/Images/hipoteca-img";
import AvaluosImg from "../components/Images/avaluos-img";
import wazeLogo from "../images/waze-logo.jpg";
import { ChristmasBanner } from "../components/ChristmasBanner";

function IndexPage() {
  const isXmas = false;

  // Set to true only in August
  const isCampaignActive = false;

  return (
    <LandingLayout>
      <Container className="p-0 position-relative" fluid>
        <div className="d-none d-md-block">
          <LandingSplashImage />
        </div>
        <Search />
      </Container>
      {isXmas && <ChristmasBanner />}
      <Container>
        {/* Marketing */}
        {isCampaignActive && (
          <Row className="">
            <Col className="mt-5 mb-3 text-center">
              <Alert color="primary">
                <p>
                  <strong>CONSIGNA</strong> tu inmueble con nosotros en el mes
                  de Agosto y te sorprenderán nuestros beneficios
                </p>
                <a
                  href="https://www.instagram.com/p/CvYm_McNyh_/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary"
                >
                  Dando click aquí
                </a>
              </Alert>
            </Col>
          </Row>
        )}
        <HighlightedListings />
        <Row>
          <Col className="servicio mt-5 mb-3">
            <h3 className="text-center" id="nuestros-servicios">
              NUESTROS SERVICIOS
            </h3>
            <hr className="title-line my-4" />
          </Col>
        </Row>

        {/* ===== Arrendamientos ===== */}
        <Row className="mb-5 d-lg-flex align-items-center bg-light p-3">
          <Col xs="12" md="6">
            <ArrendamientoImg />
          </Col>
          <Col xs="12" md="6">
            <h4 className="text-uppercase my-3">Arrendamientos</h4>
            <p>
              Encontrar el mejor arrendatario para su propiedad es nuestra
              prioridad, por eso queremos que su inversi&oacute;n este segura.
              Contamos con una p&oacute;liza de arrendamientos con Seguros
              Bolivar, la cual le da todas las garant&iacute;as para que pueda
              arrendar cualquier tipo de inmueble con total tranquilidad.
            </p>
            <p>
              <Link
                className="btn btn-primary servicio-btn mt-1"
                to="/arriendos/"
                tabIndex="-1"
                role="button"
              >
                M&aacute;s Informaci&oacute;n
              </Link>
            </p>
          </Col>
        </Row>
        {/* ===== End Arrendamientos ===== */}

        {/* ===== Ventas ===== */}
        <Row className="mb-5 d-lg-flex align-items-center bg-light p-3">
          <Col xs="12" md="6" className="order-lg-1">
            <VentasImg />
          </Col>
          <Col xs="12" md="6" className="order-lg-0">
            <h4 className="text-uppercase my-3">Venta de finca raiz</h4>
            <p>
              Sabemos que cuando quiere vender o comprar, siempre quiere estar
              seguro de la inversi&oacute;n que esta haciendo. Adem&aacute;s de
              la asesor&iacute;a para venta de proyectos. Por esto contamos con
              los mejores profesionales inmobiliarios que le ayudar&aacute;n a
              fijar el valor de su propiedad y obtener cr&eacute;ditos
              financieros
            </p>
            <p>
              <Link
                className="btn btn-primary servicio-btn mt-1"
                to="/ventas/"
                tabIndex="-1"
                role="button"
              >
                M&aacute;s Informaci&oacute;n
              </Link>
            </p>
          </Col>
        </Row>
        {/* ===== End Ventas ===== */}

        {/* ===== Prestamos hipotecarios ===== */}
        <Row className="mb-5 d-lg-flex align-items-center bg-light p-3">
          <Col xs="12" md="6">
            <HipotecaImg />
          </Col>
          <Col xs="12" md="6">
            <h4 className="text-uppercase my-3">
              Pr&eacute;stamos Hipotecarios
            </h4>
            <p>
              Somos intermediarios en pr&eacute;stamos y colocaci&oacute;n de
              dineros bajo garant&iacute;a hipotecaria en primer grado y para
              libre inversi&oacute;n.
            </p>
            <p>
              <Link
                className="btn btn-primary servicio-btn mt-1"
                to="/prestamos/"
                tabIndex="-1"
                role="button"
              >
                M&aacute;s Informaci&oacute;n
              </Link>
            </p>
          </Col>
        </Row>
        {/* ===== End Prestamos ===== */}

        {/* ==== Avaluos ===== */}
        <Row className="mb-5 d-lg-flex align-items-center bg-light p-3">
          <Col xs="12" md="6" className="order-lg-1">
            <AvaluosImg />
          </Col>
          <Col xs="12" md="6" className="order-lg-0">
            <h4 className="text-uppercase my-3">Aval&uacute;os</h4>
            <p>
              Nuestros expertos avaluadores le podrán asesorar cuando quiera
              hacer un estudio más preciso sobre el precio de propiedades
              urbanas, rurales, de renta, de industria o de maquinaria y equipo,
              as&iacute; como también de activos para corporaciones y entidades
              financieras
            </p>
            <p>
              <Link
                className="btn btn-primary servicio-btn mt-1"
                to="/avaluos/"
                tabIndex="-1"
                role="button"
              >
                M&aacute;s Informaci&oacute;n
              </Link>
            </p>
          </Col>
        </Row>
        {/* ===== End avaluos ===== */}

        {/* Contacto */}
        <Row>
          <Col md="6" className="mt-5 mb-3 bg-light rounded p-5">
            <h3 className="text-center" id="contacto">
              CONTACTO
            </h3>
            <hr className="title-line my-4" />
            <ContactForm />
          </Col>
          <Col className="d-md-none d-lg-none text-center">
            <a href="https://www.waze.com/ul?ll=4.68673148%2C-74.06090856&navigate=yes&zoom=16">
              <img src={wazeLogo} height="80" alt="waze" />
              <p>Llegar con Waze</p>
            </a>
          </Col>
        </Row>
        {/* End Contacto */}
      </Container>
    </LandingLayout>
  );
}

export default IndexPage;
