import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ArrendamientoImg = () => (
  <StaticImage
    src="../../images/arrendamiento.jpg"
    alt="arrendamientos"
  />
);

export default ArrendamientoImg;
