import React from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

function ContactForm() {
  function onSubmit(e) {
    e.preventDefault();

    // Google Ads conversion
    window.gtag("event", "conversion", { send_to: "AW-940431214/wdxyCOyw5l4Q7q63wAM" });

    const formData = new FormData(e.target);
    const values = Array.from(formData.values());
    // %0a it's new line character
    const text = `
      ${values[3].trim()} %0a %0a
      Mis datos de contacto: %0a
      Nombre: ${values[0].trim()} %0a
      Teléfono: ${values[1].trim()} %0a
      Email: ${values[2].trim()}
    `;
    window.location.href = `https://api.whatsapp.com/send?phone=${process.env.GATSBY_ARRENDAMIENTOS_PHONE_1}&text=${text}`;
  }

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label for="nombre">Nombre</Label>
        <Input type="text" name="name" required />
      </FormGroup>
      <FormGroup>
        <Label>Celular o Fijo</Label>
        <Input type="number" name="phone" required />
      </FormGroup>
      <FormGroup>
        <Label>Email</Label>
        <Input type="email" name="email" />
      </FormGroup>
      <FormGroup>
        <Label>Comentarios</Label>
        <Input type="textarea" name="comments" required />
      </FormGroup>
      <FormGroup>
        <Button type="submit">Enviar</Button>
      </FormGroup>
    </Form>
  );
}

export default ContactForm;
