import React, { Component } from "react";
import {
  Container, Form, Input, Button,
} from "reactstrap";

import listingTypes from "./listingTypes.json";
import zones from "./zones.json";

class Search extends Component {
  render() {
    return (
      <Container className="search-container mt-3">
        <Form
          action="/inmuebles"
          onSubmit={() => {
            // Google Ads conversion
            window.gtag("event", "conversion", { send_to: "AW-940431214/egetCPiGj7MBEO6ut8AD" });
          }}
        >
          <input type="hidden" name="branch" value="420" />
          <div className="form-row">
            <div className="col-12 col-md-3 mb-3 mb-md-0">
              <Input
                type="select"
                name="biz"
                defaultValue="2"
                className="form-control"
              >
                <option value="2">Venta</option>
                <option value="1">Arriendo</option>
              </Input>
            </div>
            <div className="col-12 col-md-4 mb-3 mb-md-0">
              <Input
                type="select"
                name="type"
                className="form-control"
                required
              >
                {listingTypes.map((item) => (
                  <option value={item.key} key={item.key}>
                    {item.value}
                  </option>
                ))}
              </Input>
            </div>
            <div className="col-12 mb-3 col-md-3 mb-md-0">
              <Input
                type="select"
                name="zone"
                className="form-control"
                defaultValue="-1"
              >
                <option value="-1" disabled>
                  Zona
                </option>
                {zones.map((item) => (
                  <option value={item.key} key={item.key}>
                    {item.value}
                  </option>
                ))}
              </Input>
            </div>
            <div className="col-12 col-md-2">
              <Button type="submit" color="warning" className="w-100">
                Buscar
              </Button>
            </div>
          </div>
        </Form>
        <Form
          action="/inmueble"
          onSubmit={() => {
            // Google Ads conversion
            window.gtag("event", "conversion", { send_to: "AW-940431214/egetCPiGj7MBEO6ut8AD" });
          }}
        >
          <div className="form-row mt-3">
            <div className="col-3 offset-6 col-md-2 offset-md-8">
              <Input
                type="search"
                name="codpro"
                placeholder="c&oacute;digo"
                bsSize="sm"
                required
              />
            </div>
            <div className="col-3 col-md-2">
              <Button
                type="submit"
                color="secondary"
                className="w-100"
                size="sm"
              >
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      </Container>
    );
  }
}

export default Search;
