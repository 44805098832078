import React from "react";
import { StaticImage } from "gatsby-plugin-image";

function LandingSplashImage() {
  const isHoliday = false;
  const yearRoundImageSrc = "../../images/bogota-skyline2020.jpg";
  const christmasImageSrc = "../../images/xmas-2023.jpg";

  return (
    <StaticImage
      src={isHoliday ? christmasImageSrc : yearRoundImageSrc}
      alt="Jaime Orozco"
      layout="constrained"
      aspectRatio={21 / 9}
      style={{
        maxHeight: "330px",
      }}
    />
  );
}

export default LandingSplashImage;
